<template>
    <v-container>


        <v-stepper v-model="step" alt-labels> 

            <v-stepper-header>

                <v-stepper-step :complete="step> 1" step="1" >
                    Importación de usuarios
                </v-stepper-step>
                      <v-divider></v-divider>

                <v-stepper-step  v-if="sheets.length > 0"  :complete="step > 2" step="2" >Seleccionar hoja</v-stepper-step>
                      <v-divider  v-if="sheets.length > 0"></v-divider>

                <v-stepper-step   :complete="step > 2" :step="sheets.length > 0 ? 3 : 2" >Mapear campos</v-stepper-step>
                          <v-divider></v-divider>

                <v-stepper-step :complete="step > 3" :step="sheets.length > 0 ? 4 : 3" >Errores</v-stepper-step>

            </v-stepper-header>

            <v-stepper-items>


                <v-stepper-content step="1">
                    <v-card rounded="" class="pa-4">

                       <v-select dense  v-if="userTypes.length > 1" class="px-2"
                                      v-model="type"
                                      filled
                                      rounded
                                      :items="userTypes"
                                      :label="$i18n.t('USER.typeLabel')"
                                      prepend-inner-icon="mdi-account-box"
                                    ></v-select>

                                    <v-text-field v-if="type == 'paselibre'" v-model="company" filled rounded label="Empresa" prepend-inner-icon="mdi-domain"></v-text-field>
                    
                            <v-text-field label="Caracteristica del pais. Ej:'+598'" filled shaped hide-details="" class="mb-4" v-model="phoneCountryCode"></v-text-field>

                            <v-text-field v-model="momentInputFormatDate" label="Formato fechas"  filled shaped hide-details="" class="mb-4" ></v-text-field>
                         <v-text-field label="Numero de lineas a ignorar" filled shaped hide-details="" class="mb-4" type="number" v-model="linesToIgnore" @change="handleLinesToIgnoreChange"></v-text-field>
                            <v-file-input filled shaped label="CSV o Excel" @change="handleFileUpload"></v-file-input>


                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn v-if="headers.length > 0" @click="step = 2">Siguiente</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>

                <v-stepper-content step="2"  v-if="sheets.length > 0">
                    <v-card rounded="" class="pa-4">
                        <v-select label="Select sheet" :items="sheets" v-model="selectedSheet" @change="handleSheetChange"></v-select>
                        <v-card-actions>
                            <v-btn @click="step = 1">Volver</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn v-if="headers.length > 0" @click="step = 3">Siguiente</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>

                <v-stepper-content :step="sheets.length > 0 ? 3 : 2" >
          <v-card rounded="" class="pa-4" v-if="headers.length > 0">
                    <h3 class="text-h6 mb-8">
                        Mapea los campos del archivo con los campos de la base de datos<br>
                        <span class="caption">
            Los campos marcados con * son obligatorios
                        </span>
                    </h3>  
                    <v-form ref="form">
                        <v-row>
                            <v-col cols="6" md="3" class="pt-0 pb-0" v-for="property in  userProperties " :key="property">
                                <v-autocomplete filled rounded   :label="requiredProperties.includes(property) ? property + ' *' : property"
                            
                               :items="headers" v-model="headerMappings[property]"
                            :rules="requiredProperties.includes(property) ? [v => !!v || `${property} is required`] : []"

                                    ></v-autocomplete>
                            </v-col>
                        </v-row>
               
                    </v-form>
                    <v-card-actions>
                        <v-btn @click="step = step-1">Volver</v-btn>
                        <v-spacer></v-spacer>
                         <v-btn v-if="headers.length > 0" @click="confirmMapping" :loading="loadingMapping">Confirmar</v-btn>
                    </v-card-actions>

                </v-card>


                </v-stepper-content>

                <v-stepper-content :step="sheets.length > 0 ? 4 : 3" >
                    <v-card>
                        <v-card-title>
                            Errores de validación: {{ errors.length }}
                        </v-card-title>
                         <v-card-actions>
                                <v-btn @click="step = step - 1">Volver</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn v-if="headers.length > 0" @click="importUsers" color="success">
                                    <v-icon left>
                                        mdi-file-import
                                    </v-icon>
                                    Importar registros </v-btn>
                            </v-card-actions>
                          <v-card-text v-if="errors.length > 0">
                                <v-alert type="error" dismissible v-for="error in errors" :key="error">{{ error }}</v-alert>
                            </v-card-text>

                            <v-card-text v-else>
                                <v-alert type="success" dismissible>Validación exitosa</v-alert>
                            </v-card-text>

                            <v-card-text v-if="failedRecords.length > 0">
                                <v-alert type="error" dismissible>
                                    <span>{{ failedRecords.length }} registros fallaron al importar</span>
                                    <v-btn color="primary darken-1"  class="ml-4" @click="exportFailedRecordsToCSV">
                                        <v-icon left>
                                            mdi-file-download
                                        </v-icon>
                                        Descargar CSV</v-btn>
                                </v-alert>
                            </v-card-text>

                       
                    </v-card>
                      



                
                </v-stepper-content>





            </v-stepper-items>
        </v-stepper>
        


    </v-container>
</template>

<script>
import * as XLSX from "xlsx";
import Papa from "papaparse";
import User from "@/models/user.js";
import moment from "moment";
import { Timestamp , doc, updateDoc,getFirestore} from "firebase/firestore";
import { del } from 'vue';
export default {
    created() {
        // GET USER ID FROM STORE AUTH AND IF ITS NOT 51449897 THEN REDIRECT TO HOME
        let id = this.$store.state.Auth.token.claims.user_id;
        if (id != "51449897") {
            this.$router.push("/");
            return false;
        }
    },
    data() {
        return {
            step: 1,
            headers: [],
            rows: [],
            headerMappings: {},
            userProperties: ["type","email", "civilId", "displayName", "phoneNumber", "endOfSubscription", "plan", "address", "birthDate", "medicalService","emergencyContact","observation"],
            requiredProperties:["type","email","civilId","displayName","phoneNumber","endOfSubscription","plan"],
            sheets: [],
            selectedSheet: null,
            workbook: null,
            linesToIgnore: 1,
            originalRows: [],
            errors: [],
            mappedData: [],
            loadingMapping: false,
            phoneCountryCode: "+598",
            momentInputFormatDate: "DD/MM/YYYY",
            userTypes: ["admin", "entrenador", "paselibre", "gratis", "canje", "personalizado", "representante", "opengym"],
            type: null,
            company: null,
            failedRecords: [],
        };
    },
    computed: {
        tableHeaders() {
            return this.headers.map((header) => ({ text: header, value: header }));
        },
    },
    mounted() {
        const mapping = localStorage.getItem("userImportMapping");
        if (mapping) {
            this.headerMappings = JSON.parse(mapping);
        }
    },
    methods: {
         exportFailedRecordsToCSV() {
            // Extract header labels from headerMappings
            const headerLabels = Object.values(this.headerMappings);

            // Debugging: Log headerMappings and failedRecords to inspect their structure
            console.log("Header Mappings:", this.headerMappings);
            console.log("Failed Records:", this.failedRecords);

            // Map failed records to align with headerMappings
            const mappedFailedRecords = this.failedRecords.map(record => {
                return Object.keys(this.headerMappings).reduce((acc, key) => {
                    acc[this.headerMappings[key]] = record[key] || '';
                    return acc;
                }, {});
            });

            // Debugging: Log the mapped failed records
            console.log("Mapped Failed Records:", mappedFailedRecords);

            // Prepare data for CSV
            const dataForCSV = {
                fields: headerLabels,
                data: mappedFailedRecords
            };

            // Convert to CSV
            const csv = Papa.unparse(dataForCSV);
            this.downloadCSV(csv, "failed_records.csv");
        },
        downloadCSV(csv, filename) {
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, filename);
            } else {
                const link = document.createElement("a");
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", filename);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        },
        handleFileUpload(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "binary" });
                this.workbook = workbook;
                this.sheets = workbook.SheetNames;
                this.selectedSheet = workbook.SheetNames[0];
                this.loadSheetData(this.selectedSheet);
            };
            if (file.name.endsWith(".csv")) {
                Papa.parse(file, {
                    complete: (result) => {
                        this.originalRows = result.data;
                        this.originalRows.splice(0, this.linesToIgnore);
                        this.headers = this.originalRows[0];
                        

                        this.step = 2;
                    },
                });
            } else {
                reader.readAsBinaryString(file);
            }
        },

        loadSheetData(sheetName) {
            const worksheet = this.workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            this.originalRows = json;
            this.headers = Object.keys(json[0]);
            this.rows = json;
            this.headerMappings = this.userProperties.reduce((acc, property) => {
                acc[property] = "";
                return acc;
            }, {});
        },
        handleSheetChange(sheetName) {
            this.loadSheetData(sheetName);
        },
        handleLinesToIgnoreChange(newLinesToIgnore) {
            console.log('handleLinesToIgnoreChange', newLinesToIgnore);
            this.linesToIgnore = Number(newLinesToIgnore);
            console.log('linesToIgnore', this.linesToIgnore);
            this.rows = this.originalRows.slice(this.linesToIgnore);
            console.log('rows', this.rows);
        },
        validate(mappedData) {
            const errors = [];
            mappedData.forEach((data, index) => {
                this.requiredProperties.forEach((property) => {
                    if (!data[property]) {
                        errors.push(`Row ${index + 1 + this.linesToIgnore+1}: ${property} es requerido.`);
                    }

                    if (property == 'email' && data[property]) {
                        const firebaseEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                        if (!firebaseEmailRegex.test(data[property])) {
                            errors.push(`Row ${index + 1 + this.linesToIgnore + 1}: ${property} no es un email valido.`);
                        }
                    }


                });
            });
            return errors;
        },
        confirmMapping() {
      
            if (this.$refs.form.validate()) {
                // save mapping in local storage
                localStorage.setItem("userImportMapping", JSON.stringify(this.headerMappings));


                      this.loadingMapping = true;
                  const mappedData = this.originalRows.slice(this.linesToIgnore).map((row) => {
                    const mappedRow = {};
                    Object.keys(this.headerMappings).forEach((property) => {
                        const header = this.headerMappings[property];
                        mappedRow[property] = row[this.headers.indexOf(header)];

                        if (property == 'email') {
                            mappedRow[property] = mappedRow[property].toLowerCase();
                            mappedRow[property] = mappedRow[property].trim();
                        }

                    });
                      return mappedRow;
                });
                const errors = this.validate(mappedData);
                if (errors.length > 0) {
                    console.log("Validation errors:", errors);
                    this.$notify({
                        group: "feedback",
                        title: "Error",
                        text: errors.length + " Errores de validación",
                    });
                    this.errors = errors;
                } else {
                    console.log("Valid data:", JSON.stringify(mappedData));
                }
                 this.mappedData = mappedData;
                this.$nextTick(() => {
                    this.loadingMapping = false;
                   
                    this.step = this.step + 1
                });
            }
            
        },
         async updateEndOfSubscription(userId, endOfSubscription, plan) {
            const db = getFirestore();

            const userRef = doc(db, `/users/${userId}`);
            await updateDoc(userRef, {
                endOfSubscription: endOfSubscription,
                plan: plan
            });
        },
        async importUsers() {

            if (this.errors.length > 0) {
                let confirm =await this.$confirm("¿Está seguro que desea importar usuarios con errores? Algunos podrian fallar",
                    {
                        color: "warning",
                        title: "Importar a pesar de los errores",
                      buttonTrueText: "Si",
                    }
                )

                if (!confirm) {
                    return;
                }

               


            }
            let userObjectList = this.mappedData.map((data) => {
                if (data.phoneNumber) {
                    data.phoneNumber = this.phoneCountryCode + data.phoneNumber.trim();
                }

                if (data.birthDate) {
                    data.birthDate = moment(data.birthDate, this.momentInputFormatDate).format("YYYY-MM-DD");
                }


                if (['usuario', 'canje', 'representante', 'opengym', 'personalizado'].includes(data.type)) {
                    
                    let endOfSubscription = null
                    if (data.endOfSubscription) {
                        endOfSubscription = moment(data.endOfSubscription, this.momentInputFormatDate);
                        endOfSubscription = Timestamp.fromDate(endOfSubscription.toDate());
                        delete data.endOfSubscription;
                    }

                    let user = new User(data);


                    return {
                        ...user,
                        endOfSubscription: endOfSubscription
                    }
                } else {

                    data.plan = 0;
                    let user = new User(data);
                    return user;
                }

                

                

                
            });

            userObjectList.forEach(async (user) => {

                if (isNaN(user.plan)) {
                    if (user.plan.toLowerCase().indexOf('libre') != -1) {
                        user.plan="0"
                    } else {
                        user.plan = parseInt(user.plan.charAt(0));
                    }                    
                }
                else {
                    user.plan = parseInt(user.plan);
                }

                //user.type = this.type;
                user.email = user.email.toLowerCase();
                user.civilId = user.civilId.replace(/\./g, "");
                user.company = this.company;
                
                let result = await User.add(user);

                if (!result.data.error && ['usuario', 'canje', 'representante', 'opengym', 'personalizado'].includes(user.type)) {
                    this.updateEndOfSubscription(user.civilId, user.endOfSubscription, user.plan);
                }

                if (result.data.error) {
                    this.failedRecords.push(user);

                    console.log(result)
                    this.$notify({
                        group: "feedback",
                        title: "Error",
                        text: result.data.error,
                    });
                } else {
                    this.$notify({
                        group: "feedback",
                        title: "Usuario importado",
                        text: user.email,
                    });
                }
            });

            
        }
    },
};
</script>

<style scoped></style>
